import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { CategoryItemsCountComponentProps } from './CategoryItemsCount.type';

import './CategoryItemsCount.styles';

/** @namespace PlugAndSell2/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends PureComponent<CategoryItemsCountComponentProps> {
    static defaultProps: Partial<CategoryItemsCountComponentProps> = {
        isMatchingListFilter: false,
    };

    render(): ReactElement {
        const { totalItems } = this.props;

        const label = totalItems && totalItems > 1 ? __('%s items', totalItems) : __('%s item', totalItems);

        return (
            <div block="CategoryItemsCount" elem="Count">
                ({label})
            </div>
        );
    }
}

export default CategoryItemsCountComponent;
