import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import ExpandableContentShowMore from './ExpandableContentShowMore.component';
import { ExpandableContentContainerDispatchProps, ExpandableContentShowMoreContainerMapStateProps } from './ExpandableContentShowMore.type';

/** @namespace PlugAndSell2/Component/ExpandableContentShowMore/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ExpandableContentShowMoreContainerMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/ExpandableContentShowMore/Container/mapDispatchToProps */
export const mapDispatchToProps = (): ExpandableContentContainerDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableContentShowMore);
