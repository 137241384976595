import Slider from '@material-ui/core/Slider';
import { PureComponent } from 'react';

import { CategoryPriceSliderComponentProps } from 'Component/CategoryPriceSlider/CategoryPriceSlider.type';
import { ReactElement } from 'Type/Common.type';

import './CategoryPriceSlider.style';

/** @namespace PlugAndSell2/Component/CategoryPriceSlider/Component */
export class CategoryPriceSliderComponent extends PureComponent<CategoryPriceSliderComponentProps> {
    render(): ReactElement {
        const {
            minAvailable,
            maxAvailable,
            valuesRange,
            handleChangeSlider,
            handleChangeInputMin,
            handleChangeInputMax,
            handleChangeCommittedSlider,
            displayPattern,
        } = this.props;

        return (
            <div block="CategoryPriceSlider">
                <Slider
                    min={minAvailable}
                    max={maxAvailable}
                    value={valuesRange}
                    onChange={handleChangeSlider}
                    onChangeCommitted={handleChangeCommittedSlider}
                    className="CategoryPriceSlider-Slider"
                />

                <div block="CategoryPriceSlider" elem="InputsWrapper">
                    <input block="CategoryPriceSlider" elem="Input" type="number" value={valuesRange[0]} onChange={handleChangeInputMin} />
                    <span>{displayPattern}</span>
                    <span block="CategoryPriceSlider" elem="Line" />
                    <input block="CategoryPriceSlider" elem="Input" type="number" value={valuesRange[1]} onChange={handleChangeInputMax} />
                    <span>{displayPattern}</span>
                </div>
            </div>
        );
    }
}

export default CategoryPriceSliderComponent;
