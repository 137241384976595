import { ChangeEvent, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { PRICE_SLIDER_DEBOUNCE_DELAY } from 'Component/CategoryPriceSlider/CategoryPriceSlider.config';
import { ReactElement } from 'Type/Common.type';
import { debounce } from 'Util/Request/Debounce';
import { parseCustomFiltersToObject, parseCustomFiltersToUrl } from 'Util/Url';

import CategoryPriceSlider from './CategoryPriceSlider.component';
import {
    CategoryPriceSliderComponentProps,
    CategoryPriceSliderContainerFunctions,
    CategoryPriceSliderContainerProps,
    CategoryPriceSliderContainerState,
    CategoryPriceSliderContainerWithRouterProps,
} from './CategoryPriceSlider.type';

/** @namespace PlugAndSell2/Component/CategoryPriceSlider/Container */
export class CategoryPriceSliderContainer extends PureComponent<CategoryPriceSliderContainerWithRouterProps, CategoryPriceSliderContainerState> {
    state: CategoryPriceSliderContainerState = {
        minAvailable: 0,
        maxAvailable: 0,
        valuesRange: [0, 0],
        displayPattern: '',
    };

    containerFunctions: CategoryPriceSliderContainerFunctions = {
        handleChangeSlider: this.handleChangeSlider.bind(this),
        handleChangeCommittedSlider: this.applySliderFilter.bind(this),
        handleChangeInputMin: this.handleChangeInputMin.bind(this),
        handleChangeInputMax: this.handleChangeInputMax.bind(this),
    };

    debouncedApplySliderFilter = debounce(() => {
        const { valuesRange, minAvailable } = this.state;
        const formattedValuesRange = [valuesRange[0], +(valuesRange[1] > minAvailable ? valuesRange[1] : minAvailable + 1)];
        this.setState({ valuesRange: formattedValuesRange });
        this.applySliderFilter(null, formattedValuesRange);
    }, PRICE_SLIDER_DEBOUNCE_DELAY);

    componentDidMount(): void {
        const { min, max, displayPattern } = this.props;
        this.setState({
            minAvailable: min,
            maxAvailable: max,
            valuesRange: [min, max],
            displayPattern,
        });
    }

    componentDidUpdate(_prevProps: CategoryPriceSliderContainerProps, prevState: CategoryPriceSliderContainerState) {
        const { min, max } = this.props;
        const prevMin = prevState.minAvailable;
        const prevMax = prevState.maxAvailable;

        const { location } = this.props;
        const { filterValue } = parseCustomFiltersToObject(location);

        if (!filterValue && (min !== prevMin || max !== prevMax)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                minAvailable: min,
                maxAvailable: max,
                valuesRange: [min, max],
            });
        }
    }

    containerProps(): Pick<CategoryPriceSliderComponentProps, 'minAvailable' | 'maxAvailable' | 'valuesRange' | 'displayPattern'> {
        const { minAvailable, maxAvailable, valuesRange, displayPattern } = this.state;

        return {
            minAvailable,
            maxAvailable,
            valuesRange,
            displayPattern,
        };
    }

    handleChangeSlider(e: ChangeEvent<{}>, newVal: number | number[]) {
        if (newVal && Array.isArray(newVal) && newVal.length) {
            this.setState({ valuesRange: newVal });
        }
    }

    handleChangeInputMin(e: ChangeEvent<{}>) {
        const { valuesRange, maxAvailable } = this.state;
        const { valueAsNumber } = e.target as HTMLInputElement;

        this.setState({
            valuesRange: [+(valueAsNumber < maxAvailable ? valueAsNumber : maxAvailable - 1), valuesRange[1]],
        });
        this.debouncedApplySliderFilter();
    }

    handleChangeInputMax(e: ChangeEvent<HTMLInputElement>) {
        const { valuesRange } = this.state;
        const { valueAsNumber } = e.target as HTMLInputElement;

        this.setState({
            valuesRange: [valuesRange[0], +valueAsNumber],
        });
        this.debouncedApplySliderFilter();
    }

    applySliderFilter(e: ChangeEvent<{}> | null, valuesRange: number | number[]) {
        const { location, history, filterValue } = this.props;

        if (!Array.isArray(valuesRange)) {
            return;
        }
        const filterRange = `${valuesRange[0]}_${valuesRange[1]}`;
        const filtersFromUrl = Object.fromEntries(Object.entries(parseCustomFiltersToObject(location)).map(([key, value]) => [key, value.value]));

        const newFilters = {
            ...filtersFromUrl,
            [filterValue]: filterRange,
        };
        const newUrl = parseCustomFiltersToUrl(newFilters);
        history.push({
            ...location,
            search: `?customFilters=${newUrl}`,
        });
    }

    render(): ReactElement {
        return <CategoryPriceSlider {...this.containerFunctions} {...this.containerProps()} />;
    }
}
// @ts-ignore
export default withRouter(CategoryPriceSliderContainer);
