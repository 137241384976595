import { PureComponent } from 'react';

import { ADDITIONAL_DETAILS_CMS_BLOCK_IDENTIFIER } from 'Component/CategoryAdditionalDetails/CategoryAdditionalDetails.config';
import { CategoryAdditionalDetailsProps } from 'Component/CategoryAdditionalDetails/CategoryAdditionalDetails.type';
import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'Type/Common.type';

import './CategoryAdditionalDetails.style.scss';

/** @namespace PlugAndSell2/Component/CategoryAdditionalDetails/Component */
export class CategoryAdditionalDetailsComponent extends PureComponent<CategoryAdditionalDetailsProps> {
    static defaultProps = {
        isCurrentCategoryLoaded: true,
        onlyStatic: false,
    };

    renderCategoryAdditionalDescription(): ReactElement {
        const {
            category: { additional_description, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryAdditionalDescriptionPlaceholder();
        }

        if (!additional_description) {
            return null;
        }

        return <Html content={additional_description} />;
    }

    renderCategoryAdditionalDescriptionPlaceholder(): ReactElement {
        return (
            <p>
                <TextPlaceholder length={TextPlaceHolderLength.LONG} />
            </p>
        );
    }

    render(): ReactElement {
        const { onlyStatic } = this.props;

        return (
            <div block="CategoryAdditionalDetails">
                <div block="CategoryAdditionalDetails" elem="Static">
                    <CmsBlock identifier={ADDITIONAL_DETAILS_CMS_BLOCK_IDENTIFIER} blockType="category" />
                </div>
                {!onlyStatic && (
                    <div block="CategoryAdditionalDetails" elem="Description">
                        {this.renderCategoryAdditionalDescription()}
                    </div>
                )}
            </div>
        );
    }
}

export default CategoryAdditionalDetailsComponent;
