import { connect } from 'react-redux';

import ProductConfigurableAttributesContainer from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { ProductConfigurableAttribute } from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.type';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import CategoryConfigurableAttributes from './CategoryConfigurableAttributes.component';
import {
    CategoryConfigurableAttributesComponentContainerFunctions,
    CategoryConfigurableAttributesComponentContainerPropKeys,
    CategoryConfigurableAttributesComponentProps,
    CategoryConfigurableAttributesContainerMapDispatchProps,
    CategoryConfigurableAttributesContainerMapStateProps,
    CategoryConfigurableAttributesContainerProps,
    CategoryConfigurableAttributesContainerState,
} from './CategoryConfigurableAttributes.type';

/** @namespace PlugAndSell2/Component/CategoryConfigurableAttributes/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): CategoryConfigurableAttributesContainerMapStateProps => ({
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    showProductCount: state.ConfigReducer.layered_navigation_product_count_enabled,
    childrenCategories: state.CategoryReducer.category.children || [],
});

/** @namespace PlugAndSell2/Component/CategoryConfigurableAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = (): CategoryConfigurableAttributesContainerMapDispatchProps => ({});

/** @namespace PlugAndSell2/Component/CategoryConfigurableAttributes/Container */
export class CategoryConfigurableAttributesContainer extends ProductConfigurableAttributesContainer<CategoryConfigurableAttributesContainerProps> {
    static defaultProps: Partial<CategoryConfigurableAttributesContainerProps> = ProductConfigurableAttributesContainer.defaultProps;

    state: CategoryConfigurableAttributesContainerState = {
        searchFilterValue: {},
    };

    containerFunctions: CategoryConfigurableAttributesComponentContainerFunctions = {
        ...this.containerFunctions,
        getSubCategories: this.getSubCategories.bind(this),
        handleSearchFilterValueChange: this.handleSearchFilterValueChange.bind(this),
    };

    containerProps(): Pick<CategoryConfigurableAttributesComponentProps, CategoryConfigurableAttributesComponentContainerPropKeys> {
        const { currencyCode, showProductCount, childrenCategories } = this.props;

        const { searchFilterValue } = this.state;

        return {
            currencyCode,
            showProductCount,
            childrenCategories,
            searchFilterValue,
            ...super.containerProps(),
        };
    }

    getCategorySubCategories(): string[] {
        const { childrenCategories } = this.props;

        return childrenCategories.map(({ id }) => id.toString());
    }

    getSubCategories(option: Partial<ProductConfigurableAttribute>): Partial<ProductConfigurableAttribute> {
        const { isSearchPage } = this.props;
        const optionWithSubcategories = { ...option };
        const { attribute_values = [] } = option;

        if (!isSearchPage) {
            const categoryItemsIds = this.getCategorySubCategories();
            const subCategoriesIds = attribute_values.filter((item) => categoryItemsIds.includes(item));

            optionWithSubcategories.attribute_values = subCategoriesIds;
        }

        return optionWithSubcategories;
    }

    handleSearchFilterValueChange(inputValue: string, attribute_code: string) {
        this.setState(({ searchFilterValue }: CategoryConfigurableAttributesContainerState) => ({
            searchFilterValue: {
                ...searchFilterValue,
                [attribute_code]: inputValue,
            },
        }));
    }

    render(): ReactElement {
        return <CategoryConfigurableAttributes {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryConfigurableAttributesContainer);
