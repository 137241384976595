import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import { ReactElement } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';

import { ResetButtonComponentProps } from './ResetButton.type';

import './ResetButton.style';

/** @namespace PlugAndSell2/Component/ResetButton/Component */
export class ResetButtonComponent extends PureComponent<ResetButtonComponentProps> {
    static defaultProps: Partial<ResetButtonComponentProps> = {
        mix: {},
    };

    __construct(props: ResetButtonComponentProps): void {
        super.__construct?.(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        const { onClick, resetFilters } = this.props;

        onClick();
        resetFilters();
        scrollToTop();
    }

    render(): ReactElement {
        const { mix, isContentFiltered } = this.props;

        if (!isContentFiltered) {
            return null;
        }

        return (
            <button block="ResetButton" mix={mix} onClick={this.onClick}>
                <div block="ResetButton" elem="Label">
                    {__('Clear filters')}
                </div>
                <span block="ResetButton" elem="CloseIcon">
                    <CloseIcon />
                </span>
            </button>
        );
    }
}

export default ResetButtonComponent;
