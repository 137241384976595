import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import TypographyHeader from 'Component/TypographyHeader/TypographyHeader.component';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { ReactElement } from 'Type/Common.type';

import { CategoryDetailsComponentProps } from './CategoryDetails.type';

import './CategoryDetails.style';

/**
 * Category details
 * @class CategoryDetails
 * @namespace PlugAndSell2/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends PureComponent<CategoryDetailsComponentProps> {
    static defaultProps: Partial<CategoryDetailsComponentProps> = {
        isCurrentCategoryLoaded: true,
    };

    renderCategoryText(): ReactElement {
        const {
            category: { name },
            isCurrentCategoryLoaded,
        } = this.props;
        const {
            actionName: { name: preloadName },
            isPrefetchValueUsed,
        } = window;

        const categoryName = isPrefetchValueUsed ? preloadName : name;

        if (isCurrentCategoryLoaded || isPrefetchValueUsed) {
            return <TextPlaceholder content={categoryName} />;
        }

        return <TextPlaceholder />;
    }

    renderCategoryDescriptionPlaceholder(): ReactElement {
        return (
            <p>
                <TextPlaceholder length={TextPlaceHolderLength.LONG} />
            </p>
        );
    }

    renderCategoryImagePlaceholder(): ReactElement {
        return <Image mix={{ block: 'CategoryDetails', elem: 'Picture' }} ratio={ImageRatio.IMG_CUSTOM} isPlaceholder />;
    }

    renderCategoryImage(): ReactElement {
        const {
            category: { image, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryImagePlaceholder();
        }

        if (!image) {
            return null;
        }

        return <Image mix={{ block: 'CategoryDetails', elem: 'Picture' }} src={image || ''} ratio={ImageRatio.IMG_CUSTOM} />;
    }

    renderCategoryNameAndDescription(): ReactElement {
        const {
            category: { name, description, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (id && !description) {
            return null;
        }

        if (!description) {
            return null;
        }

        if (!isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <>
                <TypographyHeader tag="h2" variant={Variant.NORMAL}>
                    <TextPlaceholder content={name} />
                </TypographyHeader>
                <Html content={description} />
            </>
        );
    }

    render(): ReactElement {
        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="Description">
                    {this.renderCategoryNameAndDescription()}
                </div>
                {this.renderCategoryImage()}
            </article>
        );
    }
}

export default CategoryDetailsComponent;
