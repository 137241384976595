/* eslint-disable react/jsx-no-bind */
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import { ReactElement } from 'Type/Common.type';
import { getFiltersCount } from 'Util/Category';

import { ResetAttributesComponentProps, ResetItem } from './ResetAttributes.type';

import './ResetAttributes.style';

/** @namespace PlugAndSell2/Component/ResetAttributes/Component */
export class ResetAttributesComponent extends PureComponent<ResetAttributesComponentProps> {
    renderSelectedOptionLabel(label: string): ReactElement {
        if (typeof label !== 'string') {
            return label;
        }

        return <Html content={label} />;
    }

    renderSelectedOption(selectedOption: ResetItem): ReactElement {
        const { toggleCustomFilter } = this.props;
        const { attribute_code, attribute_label, value_string } = selectedOption;
        const onRemove = () => toggleCustomFilter(attribute_code, value_string, '');

        return (
            <button block="ResetAttributes" elem="AttributeValue" key={value_string} onKeyDown={onRemove} onClick={onRemove} aria-label={__('Close')}>
                <div block="ResetAttributes" elem="AttributeLabel">
                    {`${attribute_label}:`}
                </div>
                <div block="ResetAttributes" elem="AttributeText">
                    {this.renderSelectedOptionLabel(selectedOption.label)}
                </div>
                <span block="ResetAttributes" elem="CloseIcon">
                    <CloseIcon />
                </span>
            </button>
        );
    }

    renderResetItem(title: string, selectedOptions: ResetItem[]): ReactElement {
        return (
            <div block="ResetAttributes" elem="Attribute" key={title}>
                {selectedOptions.map((o) => this.renderSelectedOption(o))}
            </div>
        );
    }

    renderDesktopTitle(): ReactElement {
        return (
            <h3 block="ResetAttributes" elem="Title">
                {__('Now shopping by:')}
            </h3>
        );
    }

    renderMobileTitle(): ReactElement {
        const { filtersData = {} } = this.props;

        const count = getFiltersCount(filtersData);

        return (
            <div block="ResetAttributes" elem="MobileTitle">
                {count === 1 ? __('1 filter selected') : getFiltersCount(filtersData) + __(' filters selected')}
            </div>
        );
    }

    render(): ReactElement {
        const { filtersData = {} } = this.props;

        if (!Object.keys(filtersData).length) {
            return null;
        }

        return <>{Object.entries(filtersData).map(([attrName, attrData]) => this.renderResetItem(attrName, attrData))}</>;
    }
}

export default ResetAttributesComponent;
