import { PureComponent } from 'react';

import CategoryConfigurableAttributes from 'Component/CategoryConfigurableAttributes';
import CategoryTree from 'Component/CategoryTree';
import Loader from 'Component/Loader';
import MobilePopup from 'Component/MobilePopup';
import Overlay from 'Component/Overlay';
import { ProductConfigurableAttribute } from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.type';
import ResetAttributes from 'Component/ResetAttributes';
import ResetButton from 'Component/ResetButton';
import { ReactElement } from 'Type/Common.type';

import { CATEGORY_FILTER_OVERLAY_ID } from './CategoryFilterOverlay.config';
import { CategoryFilterOverlayComponentProps } from './CategoryFilterOverlay.type';

import './CategoryFilterOverlay.style';

/** @namespace PlugAndSell2/Component/CategoryFilterOverlay/Component */
export class CategoryFilterOverlayComponent<T extends CategoryFilterOverlayComponentProps> extends PureComponent<T> {
    renderFilters(): ReactElement {
        const { availableFilters = {}, customFiltersValues, toggleCustomFilter, isMatchingInfoFilter, getFilterUrl, isSearchPage } = this.props;

        const filters = availableFilters as unknown as Record<string, Partial<ProductConfigurableAttribute>>;

        return (
            <CategoryConfigurableAttributes
                mix={{ block: 'CategoryFilterOverlay', elem: 'Attributes' }}
                isReady={isMatchingInfoFilter}
                configurable_options={filters}
                getLink={getFilterUrl}
                parameters={customFiltersValues}
                updateConfigurableVariant={toggleCustomFilter}
                isSearchPage={isSearchPage}
            />
        );
    }

    renderSeeResults(): ReactElement {
        const { onSeeResultsClick } = this.props;

        return (
            <div block="CategoryFilterOverlay" elem="SeeResults">
                <button block="CategoryFilterOverlay" elem="Button" mix={{ block: 'Button' }} onClick={onSeeResultsClick}>
                    {__('SEE RESULTS')}
                </button>
            </div>
        );
    }

    renderResetButton(): ReactElement {
        const { onSeeResultsClick } = this.props;

        return <ResetButton onClick={onSeeResultsClick} mix={{ block: 'CategoryFilterOverlay', elem: 'ResetButton' }} />;
    }

    renderResetAttributes(): ReactElement {
        const { customFiltersValues, availableFilters, toggleCustomFilter } = this.props;

        return (
            <ResetAttributes customFiltersValues={customFiltersValues} availableFilters={availableFilters} toggleCustomFilter={toggleCustomFilter} />
        );
    }

    renderHeading(): ReactElement {
        const { isContentFiltered } = this.props;

        return (
            <h3 block="CategoryFilterOverlay" elem="Heading" mods={{ isContentFiltered }}>
                {__('Shopping Options')}
            </h3>
        );
    }

    renderNoResults(): ReactElement {
        return (
            <p block="CategoryFilterOverlay" elem="NoResults">
                {__(`The selected filter combination returned no results. Please try again, using a different set of filters.`)}
            </p>
        );
    }

    renderEmptyFilters(): ReactElement {
        return (
            <>
                {this.renderNoResults()}
                {this.renderResetButton()}
                {this.renderSeeResults()}
            </>
        );
    }

    renderMinimalFilters(): ReactElement {
        return this.renderSeeResults();
    }

    renderDefaultFilters(): ReactElement {
        return (
            <div block="CategoryFilterOverlay" elem="FiltersWrap">
                {this.renderCategoryTree()}
                {this.renderFilters()}
            </div>
        );
    }

    renderContent(): ReactElement {
        const { totalPages, isProductsLoading } = this.props;

        if (!isProductsLoading && totalPages === 0) {
            return this.renderEmptyFilters();
        }

        return (
            <>
                {this.renderDefaultFilters()}
                {this.renderSeeResults()}
            </>
        );
    }

    renderLoader(): ReactElement {
        const { isInfoLoading, availableFilters } = this.props;

        const isLoaded = availableFilters && !!Object.keys(availableFilters).length;

        if (!isLoaded) {
            // hide loader if filters were not yet loaded (even once!)
            return null;
        }

        return <Loader isLoading={isInfoLoading} />;
    }

    renderCategoryTree(): ReactElement {
        const heading = __('Category');

        return (
            <div block="CategoryFilterOverlay" elem="Attributes">
                <CategoryTree heading={heading} hasParentCategory={false} />
            </div>
        );
    }

    render(): ReactElement {
        const { onVisible, onHide, totalPages, isProductsLoading, isContentFiltered, isOverlayVisible, isTablet, isMobile, onCloseFiltersClick } =
            this.props;

        if (!isProductsLoading && totalPages === 0 && !isContentFiltered) {
            return <div block="CategoryFilterOverlay" />;
        }

        return isTablet || isMobile ? (
            <MobilePopup handleMobilePopup={onCloseFiltersClick} isOpen={isOverlayVisible} title={__('Filters')}>
                <div block="CategoryFilterOverlay">
                    <div block="CategoryFilterOverlay" elem="Wrapper">
                        {this.renderContent()}
                        {this.renderLoader()}
                    </div>
                </div>
            </MobilePopup>
        ) : (
            <Overlay
                onVisible={onVisible}
                onHide={onHide}
                mix={{ block: 'CategoryFilterOverlay' }}
                id={CATEGORY_FILTER_OVERLAY_ID}
                isRenderInPortal={false}
            >
                <div block="CategoryFilterOverlay" elem="Wrapper">
                    {this.renderContent()}
                    {this.renderLoader()}
                </div>
            </Overlay>
        );
    }
}

export default CategoryFilterOverlayComponent;
