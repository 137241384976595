import CategoryPriceSlider from 'Component/CategoryPriceSlider';
import ExpandableContent from 'Component/ExpandableContent';
import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import ProductAttributeValue from 'Component/ProductAttributeValue/ProductAttributeValue.component';
import { ProductAttributeShape } from 'Component/ProductAttributeValue/ProductAttributeValue.type';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import { ProductConfigurableAttribute } from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.type';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { ReactElement } from 'Type/Common.type';
import { getPriceFilterLabel } from 'Util/Category';
import { sortBySortOrder } from 'Util/Product';
import { IndexedAttributeWithValueOption } from 'Util/Product/Product.type';

import { FILTERS_AMOUNT_TO_SHOW_SEARCHER, VALIDATE_NUMBER } from './CategoryConfigurableAttributes.config';
import { CategoryConfigurableAttributesComponentProps } from './CategoryConfigurableAttributes.type';

import './CategoryConfigurableAttributes.style';

/** @namespace PlugAndSell2/Component/CategoryConfigurableAttributes/Component */
export class CategoryConfigurableAttributesComponent extends ProductConfigurableAttributes<CategoryConfigurableAttributesComponentProps> {
    renderSubCategories(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { getSubCategories } = this.props;

        const optionWithSubcategories = getSubCategories(option);
        const { attribute_values = [], attribute_options = {} } = optionWithSubcategories;

        const attributeValues = attribute_values.length ? attribute_values : Object.keys(attribute_options);

        if (!attributeValues.length) {
            return null;
        }

        optionWithSubcategories.attribute_values = attributeValues;

        return this.renderDropdownOrSwatch(optionWithSubcategories);
    }

    renderPriceSwatch(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { currencyCode } = this.props;
        const { attribute_options, ...priceOption } = option;

        if (attribute_options) {
            // do not render price filter if it includes "*_*" aggregation
            if (attribute_options['*_*']) {
                return null;
            }

            (priceOption as Partial<ProductConfigurableAttribute>).attribute_options = Object.entries(attribute_options).reduce(
                (acc: Record<string, IndexedAttributeWithValueOption>, [key, option]) => {
                    const { label: oldLabel } = option;
                    const [from, to] = oldLabel.split('~');
                    const label = getPriceFilterLabel(from, to, currencyCode);

                    acc[key] = { ...option, label };

                    return acc;
                },
                {}
            );
        }

        return this.renderDropdownOrSwatch(priceOption);
    }

    renderDropdownOrSwatch(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { isContentExpanded } = this.props;

        const { attribute_label, attribute_code, attribute_options } = option;

        const [{ swatch_data = null }] = attribute_options ? Object.values(attribute_options) : [{}];
        const isSwatch = !!swatch_data;

        return (
            // eslint-disable-next-line react/jsx-no-bind
            <RenderWhenVisible fallback={() => <div block="ProductConfigurableAttributes" elem="Placeholders" />}>
                <ExpandableContent
                    key={attribute_code}
                    heading={attribute_label}
                    mix={{
                        block: 'ProductConfigurableAttributes',
                        elem: 'Expandable',
                    }}
                    isContentExpanded={isContentExpanded}
                >
                    {isSwatch ? this.renderSwatch(option) : this.renderDropdown(option)}
                </ExpandableContent>
            </RenderWhenVisible>
        );
    }

    renderConfigurableAttributeValue(attribute: Partial<ProductConfigurableAttribute>): ReactElement {
        const { getIsConfigurableAttributeAvailable, handleOptionClick, getLink, isSelected, showProductCount } = this.props;

        const { attribute_value } = attribute;

        return (
            <ProductAttributeValue
                key={attribute_value}
                attribute={attribute}
                isSelected={isSelected(attribute)}
                isAvailable={getIsConfigurableAttributeAvailable(attribute)}
                onClick={handleOptionClick as (o: Partial<ProductAttributeShape>) => void}
                getLink={getLink as (o: Partial<ProductAttributeShape>) => string}
                isProductCountVisible={showProductCount}
            />
        );
    }

    renderConfigurableAttributes(): ReactElement {
        const { configurable_options } = this.props;

        return sortBySortOrder(Object.values(configurable_options), 'attribute_position').map(this.renderConfigurableOption.bind(this));
    }

    renderDropdown(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { attribute_values = [] } = option;

        return (
            <div block="ProductConfigurableAttributes" elem="DropDownList">
                <ExpandableContentShowMore>
                    {attribute_values.map((attribute_value) => this.renderConfigurableAttributeValue({ ...option, attribute_value }))}
                </ExpandableContentShowMore>
            </div>
        );
    }

    renderConfigurableAttribute(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { isContentExpanded, getSubHeading } = this.props;
        const { attribute_label, attribute_code } = option;

        return (
            <ExpandableContent
                key={attribute_code}
                heading={attribute_label}
                subHeading={getSubHeading(option)}
                mix={{
                    block: 'ProductConfigurableAttributes',
                    elem: 'Expandable',
                }}
                isContentExpanded={isContentExpanded}
                isExpandableOnDesktop
                iconVariant="secondary"
            >
                {this.renderOption(option)}
            </ExpandableContent>
        );
    }

    renderConfigurableOption(option: Partial<ProductConfigurableAttribute>): ReactElement {
        const { attribute_code, frontend_class } = option;

        if (frontend_class === VALIDATE_NUMBER) {
            return this.renderSlider(option);
        }

        switch (attribute_code) {
            case 'price':
                return this.renderSlider(option);
            case 'category_id':
                return null;
            case 'category_uid':
                return null;
            default:
                return this.renderConfigurableAttribute(option);
        }
    }

    renderSlider(option: Partial<ProductConfigurableAttribute>) {
        const { isContentExpanded, getSubHeading } = this.props;

        const { attribute_code = '', attribute_values, frontend_class, attribute_label, slider_display_pattern = '' } = option;
        const heading = frontend_class === VALIDATE_NUMBER ? attribute_label : __('Price');

        const min = attribute_values && +attribute_values[0];
        const max = attribute_values && (Number.isNaN(+attribute_values[1]) ? min : +attribute_values[attribute_values?.length - 1] + 1);

        if (!min || !max) {
            return;
        }

        return (
            <ExpandableContent
                key={attribute_code}
                heading={heading}
                subHeading={getSubHeading(option)}
                mix={{
                    block: 'ProductConfigurableAttributes',
                    elem: 'Expandable',
                }}
                isContentExpanded={isContentExpanded}
                isExpandableOnDesktop
                iconVariant="secondary"
            >
                <CategoryPriceSlider min={min} max={max} displayPattern={slider_display_pattern} filterValue={attribute_code} />
            </ExpandableContent>
        );
    }

    renderOption(option: Partial<ProductConfigurableAttribute>) {
        const { attribute_values, attribute_code, attribute_options } = option;
        const { searchFilterValue, handleSearchFilterValueChange } = this.props;

        const filterValue = attribute_code ? searchFilterValue[attribute_code] : '';
        const attributeOptionsArray = Object.values(attribute_options || {});

        const attributeOptionsFiltered = attributeOptionsArray.filter((attr) => attr.label?.toLowerCase()?.includes(filterValue?.toLowerCase()));
        const attributeOptionsArr = filterValue ? attributeOptionsFiltered : attributeOptionsArray;
        const attributeValuesString = attributeOptionsArr.map((attr: IndexedAttributeWithValueOption) => attr.value_string);
        const attributeValuesFiltered = attribute_values?.filter((val) => attributeValuesString.includes(val));
        const showFilterSearcher = attribute_values && attribute_values.length >= FILTERS_AMOUNT_TO_SHOW_SEARCHER;

        return (
            <div block="ProductConfigurableAttributes" elem="DropDownList">
                {showFilterSearcher ? (
                    <div block="CategoryConfigurableAttributes" elem="FilterSearcher">
                        <div block="CategoryConfigurableAttributes" elem="FilterSearcherIcon">
                            <Icons name={IconName.MAGNIFIER} width="24px" />
                        </div>
                        <Field
                            type={FieldType.TEXT}
                            attr={{
                                name: 'searchFilters',
                            }}
                            events={{
                                onChange: (event: { target: HTMLInputElement }) => {
                                    handleSearchFilterValueChange(event.target.value, attribute_code || '');
                                },
                            }}
                        />
                    </div>
                ) : null}

                <div block="CategoryConfigurableAttributes" elem="Attributes">
                    {attributeValuesFiltered?.map((attribute_value) => this.renderConfigurableAttributeValue({ ...option, attribute_value }))}
                </div>
            </div>
        );
    }
}

export default CategoryConfigurableAttributesComponent;
