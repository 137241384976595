import { CategoryFilterOverlayComponent as SourceCategoryFilterOverlayComponent } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.component';
import { CategoryFilterOverlayComponentProps } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.type';
import ResetButton from 'Component/ResetButton';
import { ReactElement } from 'Type/Common.type';

import './CategoryFilterApplied.style';

/** @namespace PlugAndSell2/Component/CategoryFilterApplied/Component */
export class CategoryFilterAppliedComponent extends SourceCategoryFilterOverlayComponent<CategoryFilterOverlayComponentProps> {
    renderResetButton(): ReactElement {
        const { onSeeResultsClick } = this.props;

        return <ResetButton onClick={onSeeResultsClick} mix={{ block: 'CategoryFilterApplied', elem: 'ResetButton' }} />;
    }

    renderFiltersApplied(): ReactElement {
        return (
            <>
                {this.renderResetAttributes()}
                {this.renderResetButton()}
            </>
        );
    }

    render(): ReactElement {
        return (
            <div block="CategoryFilterApplied" elem="Wrapper">
                {this.renderFiltersApplied()}
                {this.renderLoader()}
            </div>
        );
    }
}

export default CategoryFilterAppliedComponent;
