import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import { ReactElement } from 'Type/Common.type';
import { history } from 'Util/History';

import { CategoryTreeComponentProps } from './CategoryTree.type';

import './CategoryTree.style';

/** @namespace PlugAndSell2/Component/CategoryTree/Component */
export class CategoryTreeComponent extends PureComponent<CategoryTreeComponentProps> {
    static propTypes = {
        category: PropTypes.object.isRequired,
        hasParentCategory: PropTypes.bool,
        parentCategory: PropTypes.object,
        showProductCount: PropTypes.bool,
    };

    static defaultProps = {
        hasParentCategory: false,
    };

    goToCategory = (url: string | undefined) => {
        const { hideActiveOverlay } = this.props;

        if (!url) {
            return;
        }

        history.push(url);
        hideActiveOverlay();
    };

    renderBackButton(): ReactElement {
        const { parentCategory, hasParentCategory } = this.props;

        if (!hasParentCategory) {
            return null;
        }

        return (
            <div block="CategoryTree" elem="BackButton">
                <Icons name={IconName.ARROW_LEFT} width="16px" height="16px" />
                <button
                    mix={{
                        block: 'CategoryTree',
                        elem: 'Button',
                    }}
                    onClick={() => this.goToCategory(parentCategory?.category_url)}
                >
                    {__('Go back to')} {parentCategory?.category_name}
                </button>
            </div>
        );
    }

    renderChildren(): ReactElement {
        const { category, hasParentCategory, showProductCount } = this.props;

        if (!category.children) {
            return;
        }

        return (
            <ul block="CategoryTree" elem="Categories" mods={{ hasParentCategory }}>
                {category.children.map(({ id, name, url, product_count }) => (
                    <li block="CategoryTree" elem="CategoryItem" key={id}>
                        <button
                            block="CategoryTree"
                            elem="Category"
                            mix={{
                                block: 'CategoryTree',
                                elem: 'Button',
                            }}
                            onClick={() => this.goToCategory(url)}
                        >
                            {name}
                        </button>
                        {showProductCount ? (
                            <div block="CategoryTree" elem="Count">
                                {product_count}
                            </div>
                        ) : null}
                    </li>
                ))}
            </ul>
        );
    }

    render(): ReactElement {
        const { category, heading } = this.props || {};

        return (
            <>
                {this.renderBackButton()}
                {category?.children?.length ? (
                    <ExpandableContent
                        heading={heading}
                        mix={{ block: 'CategoryTree' }}
                        isArrow
                        isContentExpanded
                        isExpandableOnDesktop
                        iconVariant="secondary"
                    >
                        {this.renderChildren()}
                    </ExpandableContent>
                ) : null}
            </>
        );
    }
}

export default CategoryTreeComponent;
