import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import CategoryItemsCount from './CategoryItemsCount.component';
import { CategoryItemsCountContainerMapDispatchProps, CategoryItemsCountContainerMapStateProps } from './CategoryItemsCount.type';

/** @namespace PlugAndSell2/Component/CategoryItemsCount/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): CategoryItemsCountContainerMapStateProps => ({
    totalItems: state.ProductListReducer.totalItems,
});

/** @namespace PlugAndSell2/Component/CategoryItemsCount/Container/mapDispatchToProps */
export const mapDispatchToProps = (): CategoryItemsCountContainerMapDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryItemsCount);
