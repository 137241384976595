/**
 * striphtml tags from string
 * @namespace PlugAndSell2/Util/Html/stripHTML */
export const stripHTML = (htmlString: string): string => {
    const deleteStyleTags = (htmlString: string): string => htmlString.replace(/<style([\s\S]*?)<\/style>/gi, '');

    const div = document.createElement('div');
    div.innerHTML = deleteStyleTags(htmlString);

    return div.textContent || div.innerText || '';
};
