/* eslint-disable import/prefer-default-export */

import { Category } from 'Query/Category.type';

import { CategoryActionType, UpdateCurrentCategoryAction } from './Category.type';

/**
 * Update Current Category
 * @param {String} categoryUrlPath url path Main Category object
 * @return {void}
 * @namespace PlugAndSell2/Store/Category/Action/updateCurrentCategory */
export const updateCurrentCategory = (category: Partial<Category>): UpdateCurrentCategoryAction => ({
    type: CategoryActionType.UPDATE_CURRENT_CATEGORY,
    category,
});
