import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    CategoryTreeComponentProps,
    CategoryTreeContainerMapDispatchProps,
    CategoryTreeContainerMapStateProps,
    CategoryTreeContainerProps,
} from 'Component/CategoryTree/CategoryTree.type';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import CategoryTree from './CategoryTree.component';

/** @namespace PlugAndSell2/Component/CategoryTree/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): CategoryTreeContainerMapStateProps => ({
    category: state.CategoryReducer.category,
    showProductCount: state.ConfigReducer.layered_navigation_product_count_enabled,
});

/** @namespace PlugAndSell2/Component/CategoryTree/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): CategoryTreeContainerMapDispatchProps => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

/** @namespace PlugAndSell2/Component/CategoryTree/Container */
export class CategoryTreeContainer extends PureComponent<CategoryTreeComponentProps> {
    static defaultProp: Partial<CategoryTreeContainerProps> = {
        category: {},
        heading: __('Categories'),
        showProductCount: false,
    };

    containerProps(): CategoryTreeComponentProps {
        const { category, heading } = this.props;

        const hasParentCategory = !!(category.breadcrumbs && category.breadcrumbs.length);
        const parentCategory = hasParentCategory ? category?.breadcrumbs?.slice(-1)[0] : { category_url: '', category_name: '' };

        return {
            ...this.props,
            category,
            hasParentCategory,
            parentCategory,
            heading,
        };
    }

    render(): ReactElement {
        return <CategoryTree {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTreeContainer);
