import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategoryFilterApplied from 'Component/CategoryFilterApplied/CategoryFilterApplied.component';
import { CategoryFilterOverlayContainer, mapDispatchToProps, mapStateToProps } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.container';
import { ReactElement } from 'Type/Common.type';

import { CategoryFilterOverlayContainerWithRouterProps } from './CategoryFilterApplied.type';

export { mapStateToProps, mapDispatchToProps };

/** @namespace PlugAndSell2/Component/CategoryFilterApplied/Container */
export class CategoryFilterAppliedContainer extends CategoryFilterOverlayContainer<CategoryFilterOverlayContainerWithRouterProps> {
    static defaultProps = {};

    render(): ReactElement {
        return <CategoryFilterApplied {...this.containerFunctions} {...this.containerProps()} />;
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryFilterAppliedContainer));
